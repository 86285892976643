import './App.css';
import React, { useRef, useEffect, useState } from 'react';
import bgImg from './images/bgImage.png'
import onImg from './images/first.jpg'
import twImg from './images/second.jpg'
import thImg from './images/third.jpg'
import fiImg from './images/fifth.jpg'
import siImg from './images/sixth.jpg'
import seImg from './images/seventh.jpg'
import eiImg from './images/eigth.jpg'
import niImg from './images/ninth.jpg'

function App() {
  const stickyRef = useRef(null);
  const [isStickyAtTop, setIsStickyAtTop] = useState(false);

  useEffect(() => {
    function handleScroll() {
      const boundingRect = stickyRef.current.getBoundingClientRect();
      // setIsStickyAtTop(boundingRect.top > 0);
      if(boundingRect.top <= 0){
        document.getElementsByClassName('top-section')[0].classList.add('is-top');
        document.getElementsByClassName('top-text')[0].classList.add('is-top-text');
      }
      else{
        document.getElementsByClassName('top-section')[0].classList.remove('is-top')
        document.getElementsByClassName('top-text')[0].classList.remove('is-top-text')
      }
    }

    // Attach event listener to the scroll event
    window.addEventListener('scroll', handleScroll);

    // Cleanup function to remove event listener when component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Empty dependency array ensures the effect runs only once on component mount

  return (
    <>
      <div ref={stickyRef} className='top-section'>
        <p className='top-text'>Ветеринарни препратки</p>
      </div>
      <div className='content-holder'>
        <div style={{backgroundImage:`url(${bgImg})`,backgroundRepeat:"no-repeat",
        height:'325px',
        backgroundSize:'cover',
        // float:'left',
        width:'100%',
        display:'table',
        marginBottom:'20px'
        }}>
          <div className='img-text'>
            <h1>ВЕТЕРИНАРНИ ПРЕПАРАТИ С ВИТАМИНИ И МИНЕРАЛИ</h1>
            <div className='img-line'></div>
          </div>
        </div>
      </div>

      <div className='google-map'>
        <div className="map-content">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1246.360344315397!2d24.87789635588071!3d42.01398367469639!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14acd9b30134684f%3A0x12a8cedef47e8468!2z0KHQnNCU0Jsg0KDQsNC80YPRgSDQntCe0JQ!5e0!3m2!1sbg!2sbg!4v1707308505964!5m2!1sbg!2sbg" width='380' height="320" style={{ border: '0' }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
        <div className="map-info">
          <p>За контакт:д-р Маргаритов</p>
          <p>Телефон:0897843910</p>
          <p>Email:vfmedica@yahoo.com</p>
        </div>
      </div>

      <div className='meds-container'>
        <div className='first'>
          <img
          src={onImg}
          height={500}
          ></img>
          <div className='first-ps'>
            <p style={{fontWeight:'bold'}}>ХИЛЕКТРО ПЛЮС</p>
            <p>Електролит за птици</p>
            <p>1мл. на 1л. вода</p>
            <p>Цена:40лв/1л.</p>
          </div>
        </div>
        <div className='first'>
          <img
            src={twImg}
            height={500}
            ></img>
            <div className='first-ps'>
              <p style={{fontWeight:'bold'}}>АД3Е+С ФОРТЕ</p>
              <p>Водно разтворима добавка за птици.</p>
              <p>1мл. на 2л. вода</p>
              <p>Цена:25лв/1л.</p>
          </div>
        </div>
        <div className='first'>
          <img
            src={thImg}
            height={500}
            ></img>
            <div className='first-ps'>
              <p style={{fontWeight:'bold'}}>НУТРИ АМИНОВИТ 12</p>
              <p>Водно разтворими витамини + аминокиселини.</p>
              <p>1мл. на 1л. вода</p>
              <p>Цена:40лв/1л.</p>
          </div>
        </div>
        <div className='first'>
          <img
            src={fiImg}
            height={500}
            ></img>
            <div className='first-ps'>
              <p style={{fontWeight:'bold'}}>АНТИ СТРЕС</p>
              <p>За преодоляване на стреса при птици.</p>
              <p>1мл. на 1л. вода</p>
              <p>Цена:40лв/1л.</p>
          </div>
        </div>
        <div className='first'>
          <img
            src={siImg}
            height={500}
            ></img>
            <div className='first-ps'>
              <p style={{fontWeight:'bold'}}>МИНЕРАЛИ+Д3</p>
              <p>Всички необходими минерали за птиците</p>
              <p>1мл. на 1л. вода</p>
              <p>Цена:40лв/1л.</p>
          </div>
        </div>
        <div className='first'>
          <img
            src={seImg}
            height={500}
            ></img>
            <div className='first-ps'>
              <p style={{fontWeight:'bold'}}>КАЛЦИИ+ФОСФОР</p>
              <p>1мл. на 1л. вода</p>
              <p>Цена:40лв/1л.</p>
          </div>
        </div>
        <div className='first'>
          <img
            src={eiImg}
            height={460}
            ></img>
            <div className='first-ps'>
              <p style={{fontWeight:'bold'}}>УЛТРА ВИТАМИНИ+АМИНОКИСЕЛИНИ</p>
              <p>Общо укрепващ препарат за всички видове животни.</p>
              <p>1мл. на 4л. вода</p>
              <p>Цена:60лв/1л.</p>
          </div>
        </div>
        <div className='first'>
          <img
            src={niImg}
            height={460}
            ></img>
            <div className='first-ps'>
              <p style={{fontWeight:'bold'}}>СЕЛЕД+ВИТ.Е</p>
              <p>1мл. на 2л. вода</p>
              <p>Цена:20лв/1л.</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
